import { apiCoreV3 } from "../../apiCoreV3/apiCoreV3";
import { apiEmpleo } from "../../util/ApiEmpleo";

export type TUpdateContractSelectionAdvisorNotesRequest = {
  Notas_Asesora_Seleccion: string | null;
};

const apiUpdateContractSelectionAdviserNotes = async (
  contractId: string,
  payload: TUpdateContractSelectionAdvisorNotesRequest
) => {
  const res = await apiEmpleo.patch(
    `contracts/${contractId}/selection-advisor-notes`,
    payload,
    {
      headers: {
        "Content-Type": "application/merge-patch+json",
      },
    }
  );

  return res;
};

export type TCoreV3ContractSendAlertNotesRequest = {
  notes: string;
}

const apiCoreV3ContractSendAlertNotes = async (
  contractCrmId: string,
  payload: TCoreV3ContractSendAlertNotesRequest
) => {
  const res = await apiCoreV3.post(
    `contract/${contractCrmId}/send-alert`,
    payload
  );
  return res;
}

export { apiUpdateContractSelectionAdviserNotes, apiCoreV3ContractSendAlertNotes };
